<template>
  <div class="person-detail">
    <NavBar/>
    <div class="container">
      <div class="person-detail__header">
        <router-link to="/" class="go-back"></router-link>
        <span class="person-detail__title">{{personDetail.title}}</span>
        <div class="person-detail__header-right">
          <button class="btn person-detail__header-btn like-button"></button>
          <button class="btn person-detail__header-btn share-button"></button>
        </div>
      </div>
    </div>
    <div class="person-detail__image">
      <img :src="personDetail.img" alt="">
    </div>
    <div class="person-detail__player">
      <div class="container">

      </div>
    </div>
    <div class="person-detail__info">
      <div class="container">
        <strong class="person-detail__info--name">{{personDetail.title}}</strong>
        <div class="person-detail__info--text">
          {{personDetail.content}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '../components/NavBar';

export default {
  name: 'Person',
  props: ['personDetail'],
  components: {NavBar}
}
</script>

<style lang="scss">
  @import "../assets/css/person.scss";
</style>
